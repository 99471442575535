<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Productos vendidos</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-container fluid>
              <v-row>
                <v-col cols="2">
                  <v-menu
                      v-model="menuFrom"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="filters.from"
                          label="Desde"
                          readonly
                          clearable
                          v-on="on"
                          outlined
                          hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.from"
                                   @input="menuFrom = false"
                                   no-title
                                   locale="es">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu
                      v-model="menuUntil"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="filters.until"
                          label="Hasta"
                          readonly
                          clearable
                          v-on="on"
                          outlined
                          hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.until"
                                   @input="menuUntil = false"
                                   no-title
                                   locale="es">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                      v-model="filters.product_id"
                      :items="products_api"
                      label="Producto"
                      outlined
                      item-text="description"
                      item-value="product_id"
                      clearable
                      hide-details="auto"
                      :loading="isLoading"
                      :search-input.sync="search_product"
                      hide-no-data
                      hide-selected
                      placeholder="Ingrese el producto a buscar"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue"
                         dark
                         @click="searchProducts">
                    Buscar
                  </v-btn>
                  <v-btn color="green"
                         dark
                         class="ml-2"
                         :loading="processing"
                         @click="downloadProducts">
                    Descargar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-card>
            <v-data-table
                :headers="headers"
                :items="products"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                fixed-header
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait">
            >
              <template v-slot:top>
                <span
                    class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">Todas los productos vendidos</span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.sale_date="{item}">
                <span>{{ item.sale_date | moment('DD/MM/YYYY') }}</span>
              </template>

              <template v-slot:item.subtotal="{item}">
                <span>{{ item.subtotal | currency('S/') }}</span>
              </template>

              <template v-slot:item.discount_value="{item}">
                <span>{{ item.discount_value | currency('S/') }}</span>
              </template>

              <template v-slot:item.total="{item}">
                <span>{{ (item.subtotal - item.discount_value) | currency('S/') }}</span>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import InventoryApi from '../../../apis/Inventories';

export default {
  name: 'SoldProducts',
  components: {
    OfficeLogin
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Reportes',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'FECHA', value: 'sale_date', sortable: false},
        {text: 'PRODUCTO', value: 'description', sortable: false},
        {text: 'CANTIDAD', value: 'quantity', sortable: false},
        {text: 'SUBTOTAL', value: 'subtotal', sortable: false},
        {text: 'DESCUENTO', value: 'discount_value', sortable: false},
        {text: 'TOTAL', value: 'total', sortable: false},
      ],
      processing: false,
      menuFrom: false,
      menuUntil: false,
      filters: {
        type_item: 'product',
        from: this.$moment().format('YYYY-MM-DD'),
        until: this.$moment().format('YYYY-MM-DD'),
        product_id: null,
        paginated: true,
        itemsPerPage: 10
      },
      isLoading: false,
      search_product: null,
      products_api: [],
      options:{},
      loading: false,
      dataTable: {page: 1},
      pageSelected: 1,
      perPage: 10,
    }
  },
  computed: {
    ...mapState('report-products', [
      'products',
      'file_name',
      'paginated',
    ])
  },
  watch: {
    async search_product(value) {
      if (value == null || value == undefined || value.length == 0) {
        return;
      }
      console.log('CARGANDO INFORMACION SEARCH')
      if (this.products_api.length > 0) {
        return;
      }

      if (this.isLoading) return

      this.isLoading = true
      this.loading=true
      let filters = {
        description: value
      }
      InventoryApi.getProductsFromOffice(filters)
          .then(response => {

            this.products_api = response.data;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {this.isLoading = false;this.loading=false});
    },
    options: {
      handler() {
        console.log(this.options)
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        this.loading=true
        const search = {...this.filters,perPage:this.perPage,page:this.pageSelected}
          this.getSoldProducts(search).finally(()=>this.loading=false);

      },
      deep: true,
    }
  },
  methods: {
    ...mapActions('inventories', [
      'getProductsFromOffice',
    ]),
    ...mapActions('report-products', [
      'getSoldProducts',

      'setProducts',
      'downloadSoldProducts'
    ]),
    searchProducts() {
      this.loading=true
      this.getSoldProducts(this.filters).finally(()=>this.loading=false);
    },
    async downloadProducts() {
      this.processing = true;
      let filters_data = Object.assign({}, this.filters);
      await this.downloadSoldProducts(filters_data);
      this.processing = false;
      if (this.file_name) {
        let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
        window.open(url);
      }
    }
  },
  created() {
     this.searchProducts();
  },
  destroyed() {
    this.setProducts([]);
  }
}
</script>

<style scoped>

</style>